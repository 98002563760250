












    import Vue from 'vue';
    import Component from 'vue-class-component';

    @Component({
        name: "Transaction",
        components: {},
        mixins:[]
    })
    export default class Transaction extends Vue {
        public created():void {
            if(this.$route.name === 'profile-transaction') this.$router.push({name: 'transaction-history'})
        }
        public mounted():void {
            // on componenet mounted
        }
    }
